import React from 'react';
import Timeline from '../../components/organisms/timeline/timeline';
import { graphql, useStaticQuery } from 'gatsby';

const TimelinePage = () => {
    const data = useStaticQuery(graphql`
        query Timeline {
            allContentfulPlantillaInternas(
                filter: { contentful_id: { eq: "15MyvTLjNkBdF8sXpGsuhK" } }
            ) {
                nodes {
                    referenciaContenido {
                        tituloGeneral
                        descripcion {
                            json
                        }
                        referenciaPlantillaContenido {
                            tituloBanner
                            imagenPrincipal {
                                file {
                                    url
                                }
                            }
                            tipoTitulo
                            tituloDescripcion
                            descripcion {
                                json
                            }
                        }
                    }
                }
            }
        }
    `);

    const timelineData =
        data.allContentfulPlantillaInternas.nodes[0].referenciaContenido[0];

    return (
        <Timeline
            items={timelineData.referenciaPlantillaContenido}
            desc={timelineData.descripcion}
            title={timelineData.tituloGeneral}
        />
    );
};

export default TimelinePage;
