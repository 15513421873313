import React, { useEffect, useRef, useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import useBrowserMode from '../../../shared/hooks/useBrowserMode';
import './timeline.scss';

interface TimelineItemProps {
    img?: string;
    title?: string;
    desc?: any;
    text?: string;
    active?: boolean;
    year?: string;
}

const TimelineItem: React.FC<TimelineItemProps> = ({
    img,
    title,
    desc,
    text,
    active,
    year,
}) => (
    <div
        key={text}
        className={`timeline-item ${active ? 'timeline-item--active' : ''}`}
        data-text={text}
    >
        <div className="timeline__content">
            {img && <img className="timeline__img" src={img} alt={text} />}
            {year && <h2 className="timeline__content-year">{year}</h2>}
            {title && <h3 className="timeline__content-title">{title}</h3>}
            {desc && <p className="timeline__content-desc">{desc}</p>}
        </div>
    </div>
);

interface TimelineProps {
    items: any;
    desc?: any;
    title?: string;
}

const Timeline: React.FC<TimelineProps> = ({ items, desc, title }) => {
    const timelineRef = useRef<HTMLDivElement>(null);
    const { window } = useBrowserMode();
    const [activeIndex, setActiveIndex] = useState<number>(0); // State for active item index

    useEffect(() => {
        document.body.classList.add('custom-scrollbar');
    }, []);

    useEffect(() => {
        const selectors: {
            timeline: HTMLDivElement;
            item: NodeListOf<HTMLDivElement>;
            activeClass: string;
            img: string;
        } = {
            timeline: timelineRef.current!,
            item: timelineRef.current!.querySelectorAll('.timeline-item'),
            activeClass: 'timeline-item--active',
            img: '.timeline__img',
        };

        const setActiveItem = (index: number) => {
            selectors.item.forEach((item) =>
                item.classList.remove(selectors.activeClass),
            );
            selectors.item[index].classList.add(selectors.activeClass);
            if (selectors.item[index].querySelector(selectors.img)) {
                selectors.timeline.style.backgroundImage = `url(${
                    selectors.item[index].querySelector(selectors.img)!.src
                })`;
            }
        };

        setActiveItem(activeIndex); // Set initial active item based on state

        const handleScroll = () => {
            const max = window.innerHeight;
            const pos = window.scrollY;

            selectors.item.forEach((item, index) => {
                const min = item.offsetTop;

                if (
                    index === selectors.item.length - 2 &&
                    pos > min + item.offsetHeight / 2
                ) {
                    setActiveIndex(selectors.item.length - 1); // Update state for last item
                } else if (pos <= max + min && pos >= min) {
                    setActiveIndex(index); // Update state for visible item
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [activeIndex]); // Dependency array ensures effect runs when activeIndex changes

    return (
        <div className="timeline-container" ref={timelineRef}>
            <div className="timeline__intro">
                {title && <h1 className="timeline-maintitle">{title}</h1>}
                {desc && (
                    <p className="timeline-maintext">
                        {documentToReactComponents(desc.json)}
                    </p>
                )}
            </div>
            <div className="timeline">
                {items.map((item, index) => (
                    <TimelineItem
                        key={
                            item.tituloBanner
                                ? item.tituloBanner
                                : `timeline-item-${index}`
                        }
                        img={
                            item.imagenPrincipal &&
                            item.imagenPrincipal[0].file.url
                        }
                        title={item.tituloBanner && item.tituloBanner}
                        desc={
                            item.descripcion &&
                            documentToReactComponents(item.descripcion.json)
                        }
                        text={item.tipoTitulo && item.tipoTitulo}
                        year={item.tituloDescripcion && item.tituloDescripcion}
                        active={item.text === items[activeIndex].text}
                    />
                ))}
            </div>
        </div>
    );
};

export default Timeline;
